import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { IAuditoria, IQueryParam, IListado } from 'modelos/src';
import { DialogService } from '../../../auxiliares/dialog/dialog.service';
import { AuditoriaService } from '../auditorias.service';
import { HelperService } from '../../../auxiliares/helper.service';
import { ListadosService } from '../../../auxiliares/listados.service';
import { Subscription, firstValueFrom } from 'rxjs';
import { IColumnas } from 'src/app/auxiliares/tabla/tabla.component';
import { DatePipe } from '@angular/common';
import { MostrarBodyComponent } from './mostrarBody/mostrarBody.component';
import {
  IFiltroTabla,
  IRegExpSearch,
} from '../../../auxiliares/tabla/filtro/filtro.component';

@Component({
  selector: 'app-listado-auditorias',
  templateUrl: './listado-auditorias.component.html',
  styleUrls: ['./listado-auditorias.component.scss'],
})
export class ListadoAuditoriasComponent implements OnInit, OnDestroy {
  //// Tabla nueva
  public name = 'ListadoAuditoriasComponent';
  public datos?: IAuditoria[] = [];
  public columnas?: IColumnas<IAuditoria>[];
  public totalCount = 0;
  public search: IRegExpSearch = {
    fields: ['entidad', 'metodo', 'dato._id'],
  };
  public filtros: IFiltroTabla[] = [];

  // QUERY+
  public query: IQueryParam = {
    page: 0,
    limit: this.helper.pageSize(this.name),
    sort: '-fechaCreacion',
    populate: 'usuario',
  };
  public auditorias$?: Subscription;

  constructor(
    private dialogService: DialogService,
    private service: AuditoriaService,
    public matDialog: MatDialog,
    public helper: HelperService,
    public listadoService: ListadosService,
  ) {}

  //ACCIONES
  public async eliminar(dato: IAuditoria): Promise<void> {
    const confirm = await this.dialogService.confirm(
      'Confirme la acción',
      `¿Eliminar la Auditoria ${dato._id}?`,
    );
    if (confirm) {
      try {
        await firstValueFrom(this.service.eliminar(dato._id!));
        this.helper.notifSuccess('Eliminación correcta');
      } catch (error) {
        this.helper.notifError(error);
      }
    }
  }

  public async mostrarBody(data: any) {
    const config: MatDialogConfig = {
      data,
      width: '1200px',
      panelClass: 'dialog-no-padding',
    };
    this.matDialog.open(MostrarBodyComponent, config);
  }

  //Listar

  public async actualizar(): Promise<void> {
    await this.listar();
  }

  public async listar(): Promise<void> {
    this.auditorias$?.unsubscribe();
    this.auditorias$ = this.listadoService
      .subscribe<IListado<IAuditoria>>('auditorias', this.query)
      .subscribe((data) => {
        this.totalCount = data.totalCount;
        this.datos = data.datos;
        console.debug('listado de auditorias', data);
      });
    await this.listadoService.getLastValue('auditorias', this.query);
  }

  private setColumnas() {
    this.columnas = [
      {
        header: {
          label: 'Fecha',
          sortable: true,
        },
        row: {
          field: 'fechaCreacion',
          pipe: {
            pipe: DatePipe,
            args: 'short',
          },
        },
      },
      {
        header: {
          label: 'Usuario',
        },
        row: {
          field: 'usuario',
          parse(dato) {
            return dato.usuario?.username;
          },
        },
      },
      {
        header: {
          label: 'Acción',
        },
        row: {
          parse(dato) {
            return parseoMetodo(dato.metodo);
          },
        },
      },
      {
        header: {
          label: 'Entidad',
        },
        row: {
          html(dato) {
            return parseoEntidad(dato.entidad);
          },
        },
      },
      {
        header: {
          label: 'Acciones',
        },
        row: {
          acciones: [
            {
              tipo: 'img',
              icon: 'assets/iconos/buscar.png',
              tooltip: 'Detalles',
              click: (dato) => this.mostrarBody(dato.dato),
            },
          ],
        },
      },
    ];
  }

  async ngOnInit(): Promise<void> {
    this.setColumnas();
    await Promise.all([this.listar()]);
  }

  ngOnDestroy(): void {
    this.auditorias$?.unsubscribe();
  }
}

function parseoEntidad(path?: string): string {
  if (!path) return path || '';
  const entidades: { [path: string]: string } = {
    centrooperativos: 'Centro Operativo',
    correctoras: 'Correctora',
    cromatografias: 'Cromatografía',
    cuencas: 'Cuencas',
    dispositivos: 'Dispositivo',
    grupos: 'Grupo',
    medidoresresidenciales: 'Medidor Residencial',
    unidadnegocios: 'Unidad de Negocio',
    usuarios: 'Usuario',
  };
  return entidades[path] || path;
}

function parseoMetodo(metodo?: string): string {
  if (!metodo) return metodo || '';
  const entidades: { [metodo: string]: string } = {
    post: 'Crear',
    put: 'Modificar',
    delete: 'Eliminar',
  };
  return entidades[metodo] || metodo;
}
